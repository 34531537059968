<template>
  <div>
    <Header
      class="flex"
      :title="currentCompany"
    >
      <h5 class="header-title">
        {{ currentCompany }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card position="fill">
        <div v-if="!LocationSelectionController.isLoading && LocationSelectionController.items.length > 0">
          <h5
            v-for="loc in LocationSelectionController.items"
            :key="loc.Zid"
            class="header-title"
          >
            <LocationListItem
              :location="loc"
              :selected-location="LocationSelectionController.selected"
              @selected-location="locationSelected(loc)"
            />
          </h5>
        </div>
        <div
          v-else-if="LocationSelectionController.isLoading"
          class="spinner-medium"
        />
        <p v-else>
          {{ $t('Views.LocationSelection.no_locations') }}
        </p>
      </Card>
    </div>
  </div>
</template>

<script>

/* Global Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';

/* Services */
import QueryMixin from '@/mixins/query.mixin';
import CompanyService from '@/Services/Company/company.service';
import LocationSelectionController from './location.selection.controller';

/* Local Components */
import LocationListItem from './components/location.list.item.vue';

export default {
  components: {
    Card,
    Header,
    LocationListItem,
  },
  mixins: [QueryMixin],
  data() {
    return {
      companyName: null,
      LocationSelectionController,
    };
  },
  computed: {
    currentCompany() {
      return this.companyName || this.$t('Views.LocationSelection.location');
    },
  },

  async created() {
    await LocationSelectionController.finnishedLoading;

    if (this.$route.query.floor) {
      const zid = parseInt(this.$route.query.floor, 10);
      LocationSelectionController.setLocationByFloorZid(zid);
    }

    this.companyName = await CompanyService.getRealmName() || null;
  },
  methods: {
    locationSelected(loc) {
      if (loc.Zid) {
        this.updateQuery({ floor: loc.Zid });
        LocationSelectionController.setLocation(loc);
      }
    },
  },
};

</script>
