<template>
  <div
    class="listitem-holder noselect"
    :class="{'header': location.isHeader,
             'location-item': !location.isHeader,
             'selected': !location.isHeader && UserState.savedFloorplanId === location.Zid,
    }"
    @click="selectLocation"
  >
    <div class="flex left padding padding-left padding-right padding-medium">
      <ResourceIcon
        v-if="!location.isHeader"
        src="/icons/location.svg"
        :type="selected ? 'resource reverse-fill' : 'resource reverse'"
      />

      <h6
        v-if="location.isHeader"
        class="small-medium secondary"
      >
        {{ location.title }}
      </h6>
      <h6
        v-else
        class="big-medium flex-grow"
      >
        {{ location.title }}
      </h6>
      <Kebab
        v-if="!location.isHeader && selected"
        :options="options"
        :view-select="true"
      />
    </div>
  </div>
</template>

<script>

import MapController from '@/classes/map/map.controller';
import UserState from '@/singletons/user.state.singleton';
import ResourceIcon from '@/components/icons/round.resource.icon.vue';
import Kebab from '@/components/kebab.menu.vue';
import MapState from '@/singletons/map.state.singleton';

export default {
  name: 'LocationListItem',
  components: {
    ResourceIcon,
    Kebab,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      UserState,
      options: [],
      MapState,
      views: null,
      ranOnce: false,
    };
  },
  computed: {
    selected() {
      return !this.location.isHeader && UserState.savedFloorplanId === this.location.Zid;
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'MapState.floorAsset'() {
      this.createOptions();
      this.iterateViewsObject();
    },
  },
  mounted() {
    this.createOptions();
    this.iterateViewsObject();
  },
  methods: {
    selectLocation(location) {
      this.createOptions();
      this.iterateViewsObject();
      this.$emit('selected-location', location);
    },
    createOptions() {
      const option1 = {
        text: 'Perspective',
        isDanger: false,
        action: () => { MapController.setCameraView('Perspective'); },
      };
      const option2 = {
        text: 'Top-down',
        isDanger: false,
        action: () => { MapController.setCameraView('Top-down'); },
      };
      const option3 = {
        text: '3D',
        isDanger: false,
        action: () => { MapController.setCameraView('3D'); },
      };
      this.options = [option1, option2, option3];
    },
    iterateViewsObject() {
      if (MapState.floorAsset && MapState.floorAsset.Options && MapState.floorAsset.Options.views) {
        this.views = MapState.floorAsset.Options.views;
        Object.entries(this.views).forEach(([key, view]) => {
          const option = {
            text: key,
            isDanger: false,
            action: () => { MapController.setCameraView(view.name); },
          };
          this.options.push(option);
        });
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  $icon-size: 36px;
  .left {
    gap: 1rem;
    align-items: center;
    flex-grow: 1;
  }
  .listitem-holder {
    margin-bottom: 0.75rem;
    display: flex;
    border-radius: 0.4rem;
    transition: background-color 0.2s ease-in-out;

    h5, h6 {
      margin-bottom: 0.5rem;
      justify-content: center;
    }
    .flex-grow {
      flex-grow: 1;
    }

    // &.header {
    //   h6 {
    //     color: var(--secondary-font-color);
    //     font-weight: 400;
    //   }
    // }

    &.location-item {
      h6 {
        // color: black;
        // font-weight: 600;
        margin: 0;
        padding: 1rem 0;
      }
      &:hover {
        cursor: pointer;
      }

      &:not(.selected):hover {
        background-color: rgba(112, 112, 112, 0.041);
        cursor: pointer;
      }
    }

    &.selected {
      background-color: #DEF3E7;
    }
  }

</style>
